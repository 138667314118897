/**
 * Created by zeitler on 10-11-2016.
 */
// addLoadEvent(boot);
const MIN_WIDTH = 900;
const RESP_CLASS = 'resp';
let resp;


$(document).ready(function () {
    resp = new Resp();
    resp.register('subMenuIcon')
    resp.register('menu_options')
    resp.register('presentSub')
    resp.register('header_options')
    resp.register('pageHeader')
    resp.register('pageContent')
    resp.register('subOverlay')
})

class Resp{
    constructor(){
        this._registered = [];
        this._active = true ? this._size() < MIN_WIDTH : false
        this.set_events();
    }

    register(id){
        /*Register elements to toogle class in resizing*/
        var e = document.getElementById(id)
        if (e != null){
            this._registered.push(e)
            this._add_resp_class(true)
        }
        else
            console.log(id + " not found")
    }

    set_events(){
        var that = this
        window.onresize = function(){
            that.resize()
        }
    }


    resize(){
        var changed = false
        if (!this._active && this._size() < MIN_WIDTH) {
            this._active = true
            changed = true
        }
        else if (this._active && this._size() > MIN_WIDTH){
            this._active = false
            changed = true
        }
        if (changed) {
            this._registered.forEach(function (i, idx) {
                i.classList.toggle(RESP_CLASS)
            })
        }
    }

    _size(){
        return window.innerWidth;
    }

    _add_resp_class(register=false) {
        if (register){
            if (this._size() < MIN_WIDTH) {
                this._registered[this._registered.length - 1].classList.add(RESP_CLASS)
            }
        }
        else {
            this._registered.forEach(function (i, idx) {
                i.classList.add(RESP_CLASS)
            })
        }
    }
}